import React, { Component } from 'react';
import GlobalCityGroup from "./GlobalCityGroup";

class GlobalCityGroups extends Component {
    render() {
        return (
            <form>
            <div className="row">
                {
                    this.props.groups.slice(0, 3).map((group) =>
                        <GlobalCityGroup group={group} key={group.name} selectedCities={this.props.selectedCities} onSelectedCitiesChange={this.props.onSelectedCitiesChange}/>
                    )
                }
            </div>
            <div className="row">
                {
                    this.props.groups.slice(3, 6).map((group) =>
                        <GlobalCityGroup group={group} key={group.name} selectedCities={this.props.selectedCities} onSelectedCitiesChange={this.props.onSelectedCitiesChange}/>
                    )
                }
            </div>
            <div className="row">
                {
                    this.props.groups.slice(6, 9).map((group) =>
                        <GlobalCityGroup group={group} key={group.name} selectedCities={this.props.selectedCities} onSelectedCitiesChange={this.props.onSelectedCitiesChange}/>
                    )
                }
            </div>
            <div className="row">
                {
                    this.props.groups.slice(9).map((group) =>
                        <GlobalCityGroup group={group} key={group.name} selectedCities={this.props.selectedCities} onSelectedCitiesChange={this.props.onSelectedCitiesChange}/>
                    )
                }
            </div>
            </form>
        );
    }
}

export default GlobalCityGroups;
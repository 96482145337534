import React, { Component } from 'react';

class Scoreboard extends Component {
    static calculateScore(selectedCities) {
        let sum = 0;
        if (selectedCities) {
            Object.keys(selectedCities).forEach(cityKey =>
                sum += Number(selectedCities[cityKey].value)
            );
        }
        return sum;
    };

    render() {
        return (
            <div className="text-success">
                Score: {Scoreboard.calculateScore(this.props.selectedCities)}
            </div>
        );
    }
}

export default Scoreboard;

import React, {Component} from 'react';
import ReactCountryFlag from "react-country-flag";


class GlobalCity extends Component {
    constructor(props) {
        super(props);
        this.handleSelectedCitiesChange = this.handleSelectedCitiesChange.bind(this);
    }

    static simplifyName(name) {
        return name.toLowerCase().replace(/\s/g, "-")
    }

    handleSelectedCitiesChange(event) {
        this.props.onSelectedCitiesChange(event);
    }

    isChecked(simplifiedName) {
        const thisCity = this.props.selectedCities[simplifiedName];
        if (thisCity) {
            return thisCity.checked;
        }
        return false;
    }

    render() {
        const cityName = this.props.city.name;
        const cityValue = this.props.score;

        const simplifiedName = GlobalCity.simplifyName(cityName);
        return (
            <div className="form-group form-check text-left">
                <label className="form-check-label" key={cityName} htmlFor={simplifiedName}>
                    <input className="city form-check-input" id={simplifiedName} type="checkbox"
                           checked={this.isChecked(simplifiedName)} name={simplifiedName} value={cityValue}
                           onChange={this.handleSelectedCitiesChange}/>
                    <ReactCountryFlag countryCode={this.props.city['country-code']}
                                      title={this.props.city['country-code']}
                                      aria-label={this.props.city['country-code']} style={{marginRight: '3px'}} svg/>
                    {cityName}</label>
            </div>
        );
    }
}

export default GlobalCity;
import React, {Component} from 'react';
import './App.css';
import Scoreboard from "./Scoreboard";
import GlobalCityGroups from "./GlobalCityGroups";

// Originally sourced and converted from https://en.wikipedia.org/wiki/Globalization_and_World_Cities_Research_Network
// Converted country codes to ISO 3166-1 alpha-2 codes (https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) and fixed some
// which appeared to go by NATO country codes.
const GLOBAL_CITIES = [{
    "name": "Alpha ++ cities",
    "score": 10,
    "cities": [{"country-code": "GB", "name": "London"}, {"country-code": "US", "name": "New York City"}]
}, {
    "name": "Alpha + cities",
    "score": 9,
    "cities": [{"country-code": "SG", "name": "Singapore"}, {
        "country-code": "HK",
        "name": "Hong Kong"
    }, {"country-code": "FR", "name": "Paris"}, {"country-code": "CN", "name": "Beijing"}, {
        "country-code": "JP",
        "name": "Tokyo"
    }, {"country-code": "AE", "name": "Dubai"}, {"country-code": "CN", "name": "Shanghai"}]
}, {
    "name": "Alpha cities",
    "score": 8,
    "cities": [{"country-code": "AU", "name": "Sydney"}, {
        "country-code": "BR",
        "name": "S\u00e3o Paulo"
    }, {"country-code": "IT", "name": "Milan"}, {"country-code": "US", "name": "Chicago"}, {
        "country-code": "MX",
        "name": "Mexico City"
    }, {"country-code": "IN", "name": "Mumbai"}, {"country-code": "RU", "name": "Moscow"}, {
        "country-code": "DE",
        "name": "Frankfurt"
    }, {"country-code": "ES", "name": "Madrid"}, {"country-code": "PL", "name": "Warsaw"}, {
        "country-code": "ZA",
        "name": "Johannesburg"
    }, {"country-code": "CA", "name": "Toronto"}, {"country-code": "KR", "name": "Seoul"}, {
        "country-code": "TR",
        "name": "Istanbul"
    }, {"country-code": "MY", "name": "Kuala Lumpur"}, {"country-code": "ID", "name": "Jakarta"}, {
        "country-code": "NL",
        "name": "Amsterdam"
    }, {"country-code": "BE", "name": "Brussels"}, {"country-code": "US", "name": "Los Angeles"}]
}, {
    "name": "Alpha - cities: ",
    "score": 7,
    "cities": [{"country-code": "IE", "name": "Dublin"}, {
        "country-code": "AU",
        "name": "Melbourne"
    }, {"country-code": "US", "name": "Washington, D.C."}, {
        "country-code": "IN",
        "name": "New Delhi"
    }, {"country-code": "TH", "name": "Bangkok"}, {"country-code": "CH", "name": "Z\u00fcrich"}, {
        "country-code": "AT",
        "name": "Vienna"
    }, {"country-code": "TW", "name": "Taipei"}, {"country-code": "AR", "name": "Buenos Aires"}, {
        "country-code": "SE",
        "name": "Stockholm"
    }, {"country-code": "US", "name": "San Francisco"}, {
        "country-code": "CN",
        "name": "Guangzhou"
    }, {"country-code": "PH", "name": "Manila"}, {"country-code": "CO", "name": "Bogot\u00e1"}, {
        "country-code": "US",
        "name": "Miami"
    }, {"country-code": "LU", "name": "Luxembourg City"}, {
        "country-code": "SA",
        "name": "Riyadh"
    }, {"country-code": "CL", "name": "Santiago"}, {"country-code": "ES", "name": "Barcelona"}, {
        "country-code": "IL",
        "name": "Tel Aviv"
    }, {"country-code": "PT", "name": "Lisbon"}]
}, {
    "name": "Beta + cities",
    "score": 6,
    "cities": [{"country-code": "CZ", "name": "Prague"}, {
        "country-code": "VN",
        "name": "Ho Chi Minh City"
    }, {"country-code": "US", "name": "Boston"}, {"country-code": "DK", "name": "Copenhagen"}, {
        "country-code": "DE",
        "name": "D\u00fcsseldorf"
    }, {"country-code": "GR", "name": "Athens"}, {"country-code": "DE", "name": "Munich"}, {
        "country-code": "US",
        "name": "Atlanta"
    }, {"country-code": "RO", "name": "Bucharest"}, {"country-code": "FI", "name": "Helsinki"}, {
        "country-code": "HU",
        "name": "Budapest"
    }, {"country-code": "UA", "name": "Kiev"}, {"country-code": "DE", "name": "Hamburg"}, {
        "country-code": "IN",
        "name": "Bangalore"
    }, {"country-code": "IT", "name": "Rome"}, {"country-code": "NO", "name": "Oslo"}, {
        "country-code": "US",
        "name": "Dallas"
    }, {"country-code": "EG", "name": "Cairo"}, {"country-code": "US", "name": "Houston"}, {
        "country-code": "PE",
        "name": "Lima"
    }, {"country-code": "NG", "name": "Lagos"}, {"country-code": "VE", "name": "Caracas"}, {
        "country-code": "NZ",
        "name": "Auckland"
    }, {"country-code": "ZA", "name": "Cape Town"}]
}, {
    "name": "Beta cities",
    "score": 5,
    "cities": [{"country-code": "QA", "name": "Doha"}, {"country-code": "PK", "name": "Karachi"}, {
        "country-code": "CY",
        "name": "Nicosia"
    }, {"country-code": "CH", "name": "Geneva"}, {"country-code": "UY", "name": "Montevideo"}, {
        "country-code": "DE",
        "name": "Berlin"
    }, {"country-code": "CA", "name": "Montreal"}, {"country-code": "AE", "name": "Abu Dhabi"}, {
        "country-code": "MA",
        "name": "Casablanca"
    }, {"country-code": "US", "name": "Philadelphia"}, {
        "country-code": "CA",
        "name": "Vancouver"
    }, {"country-code": "CN", "name": "Shenzhen"}, {"country-code": "BG", "name": "Sofia"}, {
        "country-code": "AU",
        "name": "Perth"
    }, {"country-code": "VN", "name": "Hanoi"}, {"country-code": "LB", "name": "Beirut"}, {
        "country-code": "AU",
        "name": "Brisbane"
    }, {"country-code": "SK", "name": "Bratislava"}, {"country-code": "BH", "name": "Manama"}]
}, {
    "name": "Beta - cities",
    "score": 4,
    "cities": [{"country-code": "MU", "name": "Port Louis"}, {
        "country-code": "US",
        "name": "Minneapolis"
    }, {"country-code": "IN", "name": "Chennai"}, {"country-code": "DE", "name": "Stuttgart"}, {
        "country-code": "DO",
        "name": "Santo Domingo"
    }, {"country-code": "BR", "name": "Rio de Janeiro"}, {
        "country-code": "KW",
        "name": "Kuwait City"
    }, {"country-code": "CN", "name": "Chengdu"}, {"country-code": "PA", "name": "Panama City"}, {
        "country-code": "US",
        "name": "Denver"
    }, {"country-code": "PK", "name": "Lahore"}, {"country-code": "SA", "name": "Jeddah"}, {
        "country-code": "TN",
        "name": "Tunis"
    }, {"country-code": "EC", "name": "Quito"}, {"country-code": "RS", "name": "Belgrade"}, {
        "country-code": "US",
        "name": "Seattle"
    }, {"country-code": "GB", "name": "Manchester"}, {
        "country-code": "GT",
        "name": "Guatemala City"
    }, {"country-code": "FR", "name": "Lyon"}, {
        "country-code": "CR",
        "name": "San Jos\u00e9, Costa Rica"
    }, {"country-code": "CN", "name": "Tianjin"}, {"country-code": "CA", "name": "Calgary"}, {
        "country-code": "JO",
        "name": "Amman"
    }, {"country-code": "PR", "name": "San Juan, Puerto Rico"}, {
        "country-code": "SV",
        "name": "San Salvador"
    }, {"country-code": "BE", "name": "Antwerp"}, {"country-code": "HR", "name": "Zagreb"}, {
        "country-code": "IN",
        "name": "Kolkata"
    }, {"country-code": "EE", "name": "Tallinn"}, {"country-code": "US", "name": "St. Louis"}, {
        "country-code": "MX",
        "name": "Monterrey"
    }, {"country-code": "IN", "name": "Hyderabad"}, {"country-code": "GB", "name": "Edinburgh"}, {
        "country-code": "US",
        "name": "San Diego"
    }, {"country-code": "DE", "name": "Cologne"}, {"country-code": "NL", "name": "Rotterdam"}, {
        "country-code": "BD",
        "name": "Dhaka"
    }, {"country-code": "PK", "name": "Islamabad"}]
}, {
    "name": "Gamma + cities",
    "score": 3,
    "cities": [{"country-code": "EC", "name": "Guayaquil"}, {
        "country-code": "US",
        "name": "Cleveland"
    }, {"country-code": "LV", "name": "Riga"}, {"country-code": "AZ", "name": "Baku"}, {
        "country-code": "AU",
        "name": "Adelaide"
    }, {"country-code": "LT", "name": "Vilnius"}, {"country-code": "GB", "name": "Birmingham"}, {
        "country-code": "GB",
        "name": "Glasgow"
    }, {"country-code": "CN", "name": "Nanjing"}, {"country-code": "CN", "name": "Hangzhou"}, {
        "country-code": "LK",
        "name": "Colombo"
    }, {"country-code": "PT", "name": "Porto"}, {"country-code": "CN", "name": "Qingdao"}, {
        "country-code": "ES",
        "name": "Valencia"
    }, {"country-code": "US", "name": "Detroit"}, {
        "country-code": "OM",
        "name": "Muscat, Oman"
    }, {"country-code": "JP", "name": "Osaka"}, {"country-code": "SI", "name": "Ljubljana"}, {
        "country-code": "UG",
        "name": "Kampala"
    }, {"country-code": "KY", "name": "George Town, Cayman Islands"}, {
        "country-code": "NI",
        "name": "Managua"
    }, {"country-code": "ZA", "name": "Durban"}, {
        "country-code": "US",
        "name": "San Jose, California"
    }, {"country-code": "RU", "name": "Saint Petersburg"}]
}, {
    "name": "Gamma cities",
    "score": 2,
    "cities": [{"country-code": "US", "name": "Phoenix, Arizona"}, {
        "country-code": "HN",
        "name": "Tegucigalpa"
    }, {"country-code": "US", "name": "Austin, Texas"}, {
        "country-code": "IN",
        "name": "Pune"
    }, {"country-code": "MX", "name": "Guadalajara"}, {"country-code": "CN", "name": "Dalian"}, {
        "country-code": "GE",
        "name": "Tbilisi"
    }, {"country-code": "TZ", "name": "Dar es Salaam"}, {
        "country-code": "CN",
        "name": "Chongqing"
    }, {"country-code": "TR", "name": "Ankara"}, {"country-code": "ZM", "name": "Lusaka"}, {
        "country-code": "IN",
        "name": "Ahmedabad"
    }, {"country-code": "US", "name": "Cincinnati"}, {
        "country-code": "PY",
        "name": "Asunci\u00f3n"
    }, {"country-code": "ZW", "name": "Harare"}, {"country-code": "SE", "name": "Gothenburg"}, {
        "country-code": "CN",
        "name": "Xiamen"
    }, {"country-code": "IQ", "name": "Mosul"}, {
        "country-code": "US",
        "name": "Kansas City, Missouri"
    }, {"country-code": "GH", "name": "Accra"}, {"country-code": "BY", "name": "Minsk"}, {
        "country-code": "US",
        "name": "Tampa, Florida"
    }, {"country-code": "IT", "name": "Turin"}, {"country-code": "AO", "name": "Luanda"}, {
        "country-code": "CI",
        "name": "Abidjan"
    }, {"country-code": "AL", "name": "Tirana"}, {"country-code": "CH", "name": "Lausanne"}, {
        "country-code": "GB",
        "name": "Leeds"
    }]
}, {
    "name": "Gamma - cities",
    "score": 1,
    "cities": [{"country-code": "TW", "name": "Taichung"}, {
        "country-code": "US",
        "name": "Charlotte, North Carolina"
    }, {"country-code": "US", "name": "Baltimore"}, {
        "country-code": "US",
        "name": "Raleigh, North Carolina"
    }, {"country-code": "GB", "name": "Belfast"}, {"country-code": "DE", "name": "Leipzig"}, {
        "country-code": "CO",
        "name": "Medell\u00edn"
    }, {"country-code": "CN", "name": "Wuhan"}, {"country-code": "CM", "name": "Douala"}, {
        "country-code": "MZ",
        "name": "Maputo"
    }, {"country-code": "MK", "name": "Skopje"}, {"country-code": "BW", "name": "Gaborone"}, {
        "country-code": "GB",
        "name": "Bristol"
    }, {"country-code": "US", "name": "Orlando, Florida"}, {
        "country-code": "SN",
        "name": "Dakar"
    }, {"country-code": "CN", "name": "Suzhou"}, {"country-code": "SE", "name": "Malm\u00f6"}, {
        "country-code": "CA",
        "name": "Edmonton"
    }, {"country-code": "CN", "name": "Changsha"}, {"country-code": "FR", "name": "Strasbourg"}, {
        "country-code": "ES",
        "name": "Bilbao"
    }, {"country-code": "IT", "name": "Bologna"}, {
        "country-code": "US",
        "name": "Columbus, Ohio"
    }, {"country-code": "NZ", "name": "Wellington"}, {"country-code": "DE", "name": "Nuremberg"}, {
        "country-code": "MM",
        "name": "Yangon"
    }, {"country-code": "CN", "name": "Xi'an"}, {"country-code": "PL", "name": "Krak\u00f3w"}, {
        "country-code": "FR",
        "name": "Marseille"
    }, {"country-code": "DE", "name": "Dresden"}, {"country-code": "CN", "name": "Shenyang"}, {
        "country-code": "US",
        "name": "Pittsburgh"
    }]
}];


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //  london: {name: "london", value: '10'}, chicago: {name: "chicago", value: '8'}
            selectedCities: {}
        };
        this.handleSelectedCitiesChange = this.handleSelectedCitiesChange.bind(this);
    }

    handleSelectedCitiesChange(event) {
        const target = event.target;
        const checked = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (checked) {
            this.setState(prevState => ({
                selectedCities: {
                    ...prevState.selectedCities,
                    [name]: checked ? {'name': name, 'value': target.value, 'checked': checked} : undefined
                },
            }), this.saveToLocalStorage);
        } else {
            const selectedCitiesCopy = Object.assign({}, this.state.selectedCities);
            delete selectedCitiesCopy[name];
            this.setState({selectedCities: selectedCitiesCopy}, this.saveToLocalStorage)
        }
    }

    saveToLocalStorage() {
        window.localStorage.setItem('selectedCities', JSON.stringify(this.state.selectedCities));
    }

    onClearArray = () => {
        this.setState({selectedCities: {}}, this.saveToLocalStorage);
    };

    render() {
        return (
            <div className="App">
                <nav className="navbar navbar-light bg-light fixed-top">
                    <a className="navbar-brand" href="/" aria-label="Global Cities">Global Cities</a>
                    <div className="navbar-nav-scroll">
                        <ul className="navbar-nav bd-navbar-nav flex-row">
                            <li className="nav-item">
                                <Scoreboard selectedCities={this.state.selectedCities}/>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <blockquote className="blockquote">
                                <p className="mb-0 text-left">I've occasionally pondered metrics around travel.
                                    I am a firm believer that cities matter more than countries (LA and NYC are very
                                    different,
                                    as are Hamburg and Berlin...) so here's a fine way to waste some time...<br/>
                                    <br/>
                                    Look at the <a href="https://en.wikipedia.org/wiki/Global_city">Global Cities
                                        list</a> below
                                    and give yourself 10 points for an Alpha++ all the way down to 1 point for each
                                    Gamma-.
                                    (I generally consider having been to a city as having spent a night there and eaten
                                    at least
                                    one sit-down meal--others can disagree. I have lots of airports that don't count.)
                                    What's your score?<br/>
                                    <br/>
                                    (Obviously the Global Cities rubric is an economically weighted metric, not artistic
                                    or cultural, but even so, there are some high correlations).<br/>
                                    <br/>
                                    If you've been to all 208 cities, you get 915 points.</p>
                                <footer className="blockquote-footer">Remy Hathaway</footer>

                            </blockquote>
                        </div>
                    </div>
                    <nav className="nav nav-pills nav-fill">
                        <form className="form-inline">
                            <button className="btn btn-outline-danger my-2 my-sm-0" type="button"
                                    onClick={this.onClearArray}>
                                Clear All
                            </button>
                        </form>
                    </nav>
                    <GlobalCityGroups groups={GLOBAL_CITIES} selectedCities={this.state.selectedCities}
                                      onSelectedCitiesChange={this.handleSelectedCitiesChange}/>
                </div>
                <footer className="footer mt-auto py-3">
                    <div className="container">
                        <span className="text-muted">Game by Remy Hathaway, Built by <a href="https://harringa.com">Justin Harringa</a></span>
                    </div>
                </footer>
            </div>
        );
    }

    componentDidMount() {
        const selectedCities = JSON.parse(window.localStorage.getItem('selectedCities'));
        // Load selectedCities from localstorage if found
        if (selectedCities) {
            this.setState({
                selectedCities: selectedCities,
            })
        }
    }
}

export default App;

import React, { Component } from 'react';
import GlobalCity from "./GlobalCity";

class GlobalCityGroup extends Component {
    render() {
        const halfOfCities = this.props.group.cities.length > 8 ? this.props.group.cities.length / 2 : this.props.group.cities.length;
        return (
            <div className="col border border-dark my-1 mx-1">
                <h2>{this.props.group.name}</h2>
                <div className="row">
                    <div className="col">
                        {
                            this.props.group.cities.slice(0, halfOfCities).map((city) =>
                                <GlobalCity score={this.props.group.score} city={city} key={city.name} selectedCities={this.props.selectedCities} onSelectedCitiesChange={this.props.onSelectedCitiesChange}/>
                            )
                        }
                    </div>
                    {this.globalCitiesColumn(this.props.group.cities.slice(halfOfCities))}
                </div>
            </div>
        );
    }

    globalCitiesColumn(cities) {
        if (cities.length > 0) {
            return <div className="col">
                {
                    cities.map((city) =>
                        <GlobalCity score={this.props.group.score} city={city} key={city.name}
                                    selectedCities={this.props.selectedCities}
                                    onSelectedCitiesChange={this.props.onSelectedCitiesChange}/>
                    )
                }
            </div>
        }
        return ""
    }
}

export default GlobalCityGroup;